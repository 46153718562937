/* eslint-disable no-useless-concat */
// eslint-disable-next-line import/prefer-default-export
export const quickDiningHero = {
  title: "Quick service restaurant POS system",
  subtext:
    "Streamline operations with an end-to-end POS system that helps you grow revenue and connect with your guests.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/quick-service-pos/demo",
  },
};
export const quickDiningSubHero = {
  title: "Technology that moves as fast as you do",
  subtext:
    "Your quick-service restaurant needs to move fast, not only to keep your guests satisfied but also to keep up with a fast-changing industry. SpotOn is here to help with a lightning-fast point-of-sale, integrated digital loyalty rewards to drive repeat visits, and robust hardware and software to streamline operations—tools that work together to save you time, save you money, and increase revenue.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/quick-service-pos/demo",
  },
};

export const quickDiningWhiteGlove = {
  mainTitle: "Personalized setup, training, and support",
  title: "White-glove service and support",
  content:
    "You have a lot on your plate. Let SpotOn lighten your load. Our team will work closely with you to customize an integrated point-of-sale system to meet your specific needs. Then, we’ll provide in-person installation and training, and continue to be there anytime you need us with 24/7 support.  Because business should be personal.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/quick-service-pos/demo",
  },
};

export const quickDiningLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "The custom solution you deserve",
      blockSubTitle:
        "What if you could have a solution that would increase sales, cut costs, and help manage the day-to-day operations of your QSR? With SpotOn, you can. Our platform combines a fast and easy point-of-sale with digital loyalty, contactless payments, unparalleled reporting, labor management, and more.",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/quick-service-pos/demo",
      },
    },
  ],
};

export const quickDiningTrusted = [
  {
    title: "74%",
    subtext: "Of guests choose businesses with a strong loyalty reward program",
  },
  {
    title: "67%",
    subtext: "More visits with online ordering",
  },
  {
    title: "$7,500+",
    subtext: "Monthly savings compared to third-party delivery apps",
  },
];

export const quickDiningMultiSection = {
  sectionTitle: "The right tools for quick service",
  featureBlocks: [
    {
      blockTitle: "SpotOn Restaurant point-of-sale",
      blockSubTitle:
        "Work faster and smarter with a quick-service POS that combines all the features you need to create an amazing guest experience.",
      blockList: [
        "Custom menu build & table layouts",
        "Lightning-fast ordering & payments",
        "Handheld POS with contactless payments",
        "Guest Wi-Fi with email capture",
        "Real-time updates & reporting",
        "Remote back office",
        "Multi-location management",
      ],
      blockImg: "qsr-c.png",
      ImgAltTag: "quick service pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/quick-service-pos/demo",
      },
    },
    {
      blockTitle: "Boost customer loyalty. \n" + "And revenue",
      blockSubTitle:
        "Increase sales by offering loyalty rewards that encourage your guests to visit more often. SpotOn Loyalty is easy to set up, easy to run, and it’s fully integrated with the checkout process to keep the line moving fast.",
      blockImg: "loyalty-mobile-laptop.png",
      ImgAltTag: "quick service pos",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/loyalty",
      },
    },
    {
      blockTitle: "SpotOn Serve",
      blockSubTitle:
        "Use our lighting-fast handheld POS as a line-buster or to take orders curbside, tableside, or anywhere else you want to interact with guests.",
      blockImg: "rest-a.png",
      ImgAltTag: "quick service pos",
      blockList: [
        "Send orders to the kitchen in real-time",
        "Accept payments on-the-spot with EMV, Apple Pay, and Android Pay card reader",
        "Capture guest data with SpotOn Loyalty",
        "Built-in touchscreen display and thermal printer",
        "Extended range with Wi-Fi and 4G LTE connectivity",
      ],
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/quick-service-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Order",
      blockSubTitle:
        "Delivery and take-out have become an essential part of your success. Online ordering from SpotOn makes ordering and paying easy for your guests while eliminating costly commissions from third-party apps. Let your guests order take-out, delivery, and e-gift cards directly from your website with full POS integration to streamline operations.",
      blockImg: "casual-online-ordering.png",
      ImgAltTag: "quick service pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/online-order-and-delivery",
      },
    },
    {
      blockTitle: "SpotOn Delivery, Powered by DoorDash",
      blockSubTitle:
        "SpotOn Delivery gives you access to the DoorDash delivery network for a low flat fee instead of the costly commissions associated with third-party apps. You also keep your own guest data, so you’re in control of marketing to your guests instead of the competition.",
      blockList: [
        "Guests order directly from your website",
        "Flat rate fee* with the ability to pass costs on to guests",
        "DoorDash handles delivery logistics",
        "Keep your guest data to to drive repeat orders",
      ],
      appendix: `*$7.95 per delivery; $9.95 per delivery in California`,
      blockImg: "delivery-c.png",
      ImgAltTag: "quick service pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/delivery",
      },
    },
    {
      blockTitle: "Turbocharge your kitchen with a KDS",
      blockSubTitle:
        "Improve kitchen productivity with a fast and easy-to-use kitchen display screen. Orders get funneled from your POS stations directly to your KDS and the bump bar makes it easy for your staff to send and retrieve orders.",
      blockImg: "qsr-kds.png",
      ImgAltTag: "quick service pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/quick-service-pos/demo",
      },
    },
  ],
};

export const quickDiningCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/quick-service-pos/demo",
  },
};

export const quickDiningVideo = {
  title: "See SpotOn solutions in action",
  videoBtn: "Watch video",
  posterImg: "quick-service-video.png",
  posterWrapperClass: "relative",
  videoUrlId: "ryt5aAUkLuA",
};

export const quickDiningTestimonials = {
  title: "What other business owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const quickDiningArticles = {
  title: "Expert advice on growing your restaurant",
  bgText: "Articles.",
};

export const quickDiningFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const quickServiceCar = {
  title: "Run a different type of restaurant",
  subtext: "QSR restaurants are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
    {
      bg: "food-truck.png",
      cardTitle: "Food Trucks",
      cardContent: "Tech that can keep up with your food truck",
      icon: "food-trucks.png",
      linkTo: "/food-truck-pos",
    },
  ],
};
