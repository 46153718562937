import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  quickDiningHero,
  quickDiningSubHero,
  quickDiningWhiteGlove,
  quickDiningLargeFeatureA,
  quickDiningTrusted,
  quickDiningMultiSection,
  quickDiningCTA,
  quickDiningVideo,
  quickDiningTestimonials,
  quickServiceCar,
} from "../../data/quick-service-data";

import {
  barbwireBarbecueSlides,
  heroSuccess,
} from "../../data/success-stories-data";

import heroBg from "../../images/hero-images/quickk-service-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/QuickServiceLF")
);
const TrustedNumbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../components/SuccessStories/SuccessStories")
);
const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const BusinessTypes = loadable(() =>
  import("../../components/BusinessTypes/BusinessTypes")
);
// const CapterraBanner = loadable(() =>
//   import("../../components-v2/CapterraBanner")
// );
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

const partnersTitle = "Used by these popular restaurants and bars:";
const whiteGloveImport = "quick-serve-white-glove.png";

const QuickService = () => {
  return (
    <Layout>
      <SEO
        title="Quick Service Restaurant POS System & Software | SpotOn"
        description="Quick Service POS Systems help your restaurant or business become lighting fast & grow customer loyalty. Get a free online demo of our quick service POS today."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/quick-service-pos/"
        productSchemaSR
      />
      <BHero sectionData={quickDiningHero} heroBg="quick-service-hero.png" />
      <NoVisualsCustom sectionData={quickDiningSubHero} simple />
      <WhiteGlove
        sectionData={quickDiningWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={quickDiningLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers
        title="By the numbers"
        numbersArray={quickDiningTrusted}
      />
      <LargeFeatures
        sectionData={quickDiningMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      {/* <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-restaurant-pos.png"
        title={`The <span class="text-primary">easiest</span> restaurant POS system to use according to real user reviews<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.capterra.com/p/restaurant-pos/226928/spoton/references?g=na&c=&c=&c=&sc=6321593"
      /> */}
      <LargeCta sectionData={quickDiningCTA} />
      <SuccessStories
        sectionData={heroSuccess}
        slides={barbwireBarbecueSlides}
      />
      <VideoSection sectionData={quickDiningVideo} />
      <TopRestaurantsLogos sectionTitle={partnersTitle} />
      <TestmonialReviews sectionData={quickDiningTestimonials} />
      <BusinessTypes sectionData={quickServiceCar} />
    </Layout>
  );
};

export default QuickService;
